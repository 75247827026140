import React from "react";
import SettingsProvider from "./context/SettingsContext";
import { BrowserRouter as Router } from "react-router-dom"; // Dodaj import dla komponentu Router

import AppRoutes from './settings/AppRoutes';
import './index.css'
//import ScrollSnap from './components/ScrollSnap'
function App() {
  return (
    <SettingsProvider>
      <Router> 
      <AppRoutes/>
      </Router> 
      {/* <ScrollSnap/> */}
    </SettingsProvider>
  );
}

export default App;