import React from "react";
import './index.css';

const UnderConstruction = () => {
    return (
        
            <div className="under-construction-container">
                under construction
            </div>
       
    );
};

export default UnderConstruction;
