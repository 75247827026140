import React, { useState,useEffect } from 'react';
import './index.css'
import Compass from './buttons/Compass'
const MainMenu = ({ pages, currentIndex, swiperSwipeTo }) => {


    const backgroundSizeMap ={
        0: '250% 210%',
        1: '240% 240%',
        2: '100% 100%',
        3: '180% 120%',
        4: '250% 160%',
    }

    const backgroundSizeFogMap ={
        0: '50% 90%',
        1: '50% 40%',
        2: '50% 40%',
        3: '50% 50%',
        4: '50% 100%',
    }

    const backgroundPositionYMap ={
        0: '50%',
        1: '90%',
        2: '100%',
        3: '90%',
        4: '60%',
    }

    useEffect(() => {
        
    }, [currentIndex]);

    useEffect(() => {
        const step = 100 / (pages.length - 1);
        const backgroundPosition = `${currentIndex * step}% ${backgroundPositionYMap[currentIndex]}`;
        const backgroundSize = `${backgroundSizeMap[currentIndex]}`;

        // Ustaw pozycję tła
        const homeContener = document.querySelector('.main-menu');
        homeContener.style.backgroundPosition = backgroundPosition;
        homeContener.style.backgroundSize = backgroundSize;

        const backgroundPositionY = backgroundPositionYMap[currentIndex];
    
        // Ustawienie tylko pozycji Y tła
        const mainMenuButtonContainer = document.querySelector('.main-menu-button-container');
        mainMenuButtonContainer.style.backgroundSize = backgroundSizeFogMap[currentIndex];
        
    }, [currentIndex]);

    return (
        <div className="main-menu">
        <div className="main-menu-button-container">
 


{pages.map((page, idx) => {
  const isActive = currentIndex === idx;
  const ButtonComponent = React.cloneElement(page.buttonComponent, {
    className: `icon ${isActive ? 'active-icon' : ''}`,
    'aria-label': page.label, 
    isActive,
    currentIndex,
    idx
  });

  return (
    <div 
      key={idx} 
      className={`box ${isActive ? 'active' : ''}`} 
      onClick={() => swiperSwipeTo(idx)}
    >
      {ButtonComponent}
      {isActive && <div className='box-label'>{page.label}</div>}
    </div>
  );
})}


          {/* {pages.map((page, idx) => {
            const isActive = currentIndex === idx;
            const IconComponent = page.icon;
            return (
              <div 
                key={idx} 
                className={`box ${isActive ? 'active' : ''}`} 
                onClick={() => setCurrentIndex(idx)}
                disabled={true}
              >
                {typeof IconComponent === 'string' 
                  ? <img src={IconComponent} alt={page.label} /> 
                  : <IconComponent className={`icon ${isActive ? 'active-icon' : ''}`} />
                }
                {isActive ? <div className='box-label'>{page.label}</div> : ''}
              </div>
            );
          })} */}
          {/* </div>
          </div> */}
        </div>
        </div>
      );
}

export default MainMenu;
