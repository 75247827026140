import React from "react";
import './index.css';

const Layout = ({ children }) => {
    return (
        <div className="layout-container">
            <div className="content-container">
                {children}
            </div>
        </div>
    );
};

export default Layout;
