import React from "react";
import { Suspense } from "react";
import { Routes, Route } from "react-router-dom";
import Home from "../container/Home";


const AppRoutes = () => {
  const useRouteData = (children) => <Suspense>{children}</Suspense>;
  return (
    <Routes>
      <Route path="/" element={<Home />} />
      {/* Dodaj inne trasy tutaj, jeśli istnieją */}
    </Routes>
  );
};

export default AppRoutes;