import React, { useEffect } from "react";
import VisibilitySensor from 'react-visibility-sensor';
import { useSettingsCtx } from "../../context/SettingsContext";
import UnderConstruction from "../UnderConstruction";

const SomePage5 = ({ page }) => {
    const { setMainSection } = useSettingsCtx();
  
    const handleVisibilityChange = (isVisible) => {
      if (isVisible) {
        setMainSection("SomePage5");
      }
    };

    const style = {
      backgroundImage: `url(${page.background})`,
    };

    return (
      <VisibilitySensor onChange={handleVisibilityChange}>
         <div className="contentSubPageBackground livePage" style={style}>
        <div className="contentSubPage scrollbar-thin  centered-block "
          
        ><UnderConstruction/></div>
        </div>
      </VisibilitySensor>
    );
  };
  
  SomePage5.label = "Some Page5";
  export default SomePage5;