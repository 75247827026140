import React from 'react';
import './Compass.css';

const Compass = ({idx,currentIndex,background, isActive,children }) => {

// Combined background size and position map
const backgroundSizePositionMap = {
  0: { // wybrany 
    0: ['90%', '95% 75%'], // pierwszy button
    1: ['240%', '35% 38%'],
    2: ['185%', '20% 1%'],
    3: ['340%', '9% 25%'],
    4: ['400%', '62% 36%'],
  },
  1: {
    0: ['112%', '14% 50%'],
    1: ['170%', '95% 75%'],
    2: ['432%', '46% 75%'],
    3: ['150%', '58% 26%'],
    4:  ['287%', '50% 86%'],
  },
  2: {
    0: ['312%', '14% 80%'],
    1: ['110%', '85% 75%'],
    2: ['240%', '75% 75%'],
    3: ['150%', '65% 75%'],
    4: ['300%', '55% 75%'],
  },
  3: {
    0: ['212%', '14% 80%'],
    1: ['140%', '15% 45%'],
    2: ['240%', '95% 55%'],
    3: ['150%', '25% 65%'],
    4: ['100%', '45% 35%'],
  },
  4: {
    0: ['112%', '24% 80%'],
    1: ['190%', '9`% 75%'],
    2: ['240%', '25% 75%'],
    3: ['150%', '35% 55%'],
    4: ['100%', '74% 75%'],
  },
  
};



  const mergeBackgroundStyles = () => {
    const defaultBackground = 'radial-gradient(ellipse at center, rgb(107 103 167 / 0%) 24%, rgb(0 0 0 / 58%) 59%)';
    if (isActive) {
      return defaultBackground;
    }
    const backgroundImage = `url(${background})`;
    return `${backgroundImage}, ${defaultBackground}`;
  };

  let backgroundPosition = '';
  let backgroundSize = '';
  
  if (!isActive) {
    const [size, position] = backgroundSizePositionMap[currentIndex][idx];
    backgroundPosition = `${position}, center`;
    backgroundSize = `${size}, cover`;
  }

  
  

  const circleStyle = {
    backgroundImage: mergeBackgroundStyles(),
    backgroundSize,
    backgroundPosition,
    // backgroundSize: 'cover, cover', 
    // backgroundPosition: 'center, center',
    // overflow: 'hidden'
  };

  return (
    <div className="container">
      <div className={`circle ${isActive ? 'animate' : ''}`} style={circleStyle}>
        <div className="compass-scale">
          <div><span>N</span></div>
          <div><span>E</span></div>
          <div><span>S</span></div>
          <div><span>W</span></div>
          <div className='small-scale'></div>
          <div className='small-scale'></div>
          <div className='small-scale'></div>
          <div className='small-scale'></div>
        </div>
        <div className='compass-content'>
        {children}
        </div>
      </div>
    </div>
  );
};

export default Compass;
