import React, { createContext, useContext, useEffect, useState } from 'react';

const SettingsContext = createContext({
  mainSection: 2,
  setMainSection: () => undefined,
});

const SettingsProvider = ({ children }) => {
  const [mainSection, setMainSection] = useState(null);

  const value = {
    mainSection,
    setMainSection,
  };

  useEffect(() => {
   // console.log("mainSection zmieniło się na:", mainSection);
    // Tutaj możesz wykonać dowolne operacje, które chcesz, gdy mainSection się zmieni
  }, [mainSection]);


  return (
    <SettingsContext.Provider value={value}>
      {children}
    </SettingsContext.Provider>
  );
};

export const useSettingsCtx = () => useContext(SettingsContext);
export default SettingsProvider;
