import axios from 'axios';

const api = axios.create({
  baseURL: '/api',
  // inne wspólne ustawienia
});

// Ustawienie interceptora dla tokenów CSRF, jeśli jest to konieczne
api.interceptors.request.use(config => {
  // Dodaj token CSRF do konfiguracji żądania, jeśli jest wymagany
  return config;
});

export const fetchStaticJson = async (filePath) => {
  try {
    const response = await api.get(filePath);
   // console.log(response)
    if (response.status === 200) {
      // Pomyślnie pobrano i zwrócono zawartość JSON jako obiekt
      return response.data;
    } else {
      // Odpowiedź nie jest kodem 200 OK, więc rzuć błąd
      throw new Error(`Request failed with status code ${response.status}`);
    }
  } catch (error) {
    // Błąd w zapytaniu lub w odpowiedzi, zwróć informacje o błędzie
    console.error('There was an error fetching the JSON:', error);
    throw error; // Rzuć ponownie błąd, aby można było go obsłużyć w komponencie
  }
};


export const queryPhpBackend = () => {
  // Implementacja metody do zapytań do backendu PHP
};

// Eksportuj użyteczne metody
export default api;
